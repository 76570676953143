<template>
  <!-- 首页 -->
  <div class="padding24 color_white box">
    <div class="banner">
      <img src="../../assets/images/img_signed.png" alt="" />
      <div>欢迎使用申怡读书管理后台</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
 
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  position: relative;
  .banner {
    width: 100%;
    height: 100%;
    img {
      width: 50%;
      margin-left: 25%;
      margin-top: 5%;
    }
    div {
      margin-top: 30px;
      margin-left: 100px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #525252;
      line-height: 50px;
      text-align: center;
    }
  }
}
</style>